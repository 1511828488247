@import "./vars";

body {
    background-color: $green-gray;
    font-family: $font-family;
}

.container {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
}
