@import "../base/vars";

.trial {
    padding-top: 300px;
}

.trial__title {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    color: $green;
}

.trial__loginReg {
    text-align: center;
    padding-top: 30px;
}

.loginReg__btn {
    color: $white;
    background-color: $blue;
    padding: 11px 32px;
    border-radius: 16px;
    width: 385px;
    text-align: center;
}

.loginReg__btn:hover {
    background-color: $blue_login;
    transition: background-color 0.5s;
}

@media (max-width: 760px) {
    .trial {
        padding-top: 130px;
    }

    .trial__title {
        font-size: 24px;
    }

    .trial__loginReg {
        padding-top: 15px;
    }

    .loginReg__btn {
        padding: 7px 30px;
    }
}

@media (max-width: 375px) {
    .trial {
        padding-top: 100px;
    }
    .trial__title {
        font-size: 14px;
    }

    .loginReg__btn {
        width: 250px;
    }
}
