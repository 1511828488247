@import "@/styles/base/base";

.header {
    padding: 60px 0;
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
}

.header__text {
    padding-left: 112px;
    color: $blue;
    font-size: 42px;
}

.header__textGreen {
    color: $green;
}

.header__login {
    display: flex;
    padding-right: 162px;
    gap: 40px;
}

.loginLog__btn {
    color: $blue;
    background-color: $white;
    padding: 11px 32px;
    border: 1px solid $blue;
    border-radius: 16px;
    width: 170px;
}

.loginLog__btn:hover {
    color: $blue_login;
    border: 1px solid $light_blue;
    transition: background-color 0.5s;
}

.loginReg__btn {
    color: $white;
    background-color: $blue;
    padding: 12px 32px;
    border-radius: 16px;
    width: 385px;
}

.loginReg__btn:hover {
    background-color: $blue_login;
    transition: background-color 0.5s;
}
@media (max-width: 1120px) {
    .header__login {
        flex-direction: column;
        padding-right: 28px;
        text-align: center;
    }
}

@media (max-width: 760px) {
    .header {
        padding: 0;
        position: relative;
    }

    .header__text {
        font-size: 24px;
        padding-top: 56px;
        padding-left: 56px;
    }

    .header__login {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding-right: 0;
        position: absolute;
        top: 250px;
        left: 180px;
    }

    .loginLog__btn {
        padding: 7px 55px;
        width: 385px;
    }

    .loginReg__btn {
        padding: 7px 30px;
    }
}

@media (max-width: 590px) {
    .header__login {
        left: 90px;
    }
}

@media (max-width: 375px) {
    .header__text {
        font-size: 14px;
        padding-top: 20px;
        padding-left: 30px;
    }

    .header__login {
        top: 160px;
        left: 60px;
    }

    .loginLog__btn {
        width: 170px;
    }

    .loginReg__btn {
        width: 250px;
    }
}
