@import "./fonts";

$font-family: "Unbounded";

$light_green: #e7faf4;
$blue: #2233c9;
$blue_login: #3c73ff;
$light_blue: #658ef5;
$white: #ffffff;
$green_gray: #f2fbf8;
$green: #2bad95;
$gray: #989898;
