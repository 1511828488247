@import "@/styles/base/base";

.suggestions__title {
    font-size: 42px;
    text-align: center;
    font-weight: 700;
}

.suggestions__items {
    display: flex;
    padding: 110px 50px 0 50px;
    gap: 23px;
    justify-content: center;
}

.suggestions__item {
    max-width: 515px;
    height: 166px;
    background-color: $light_green;
}

.suggestions__itemText {
    text-align: center;
    font-size: 30px;
    color: $green;
    padding: 25px;
}

.suggestions__itemsImg {
    display: none;
}

@media (max-width: 760px) {
    .suggestions__title {
        font-size: 24px;
    }

    .suggestions__items {
        padding-top: 25px;
    }

    .suggestions__item {
        max-width: 212px;
        height: 74px;
    }

    .suggestions__itemText {
        font-size: 12px;
        padding: 15px 2px;
    }
}

@media (max-width: 375px) {
    .suggestions__itemsImg {
        display: block;
    }

    .suggestions__title {
        font-size: 18px;
        padding-top: 15px;
    }

    .suggestions__items {
        padding-top: 20px;
    }

    .suggestions__item {
        height: 100%;
    }

    .suggestions__itemText {
        font-size: 12px;
        color: $green;
        padding: 10px 30px;
    }
}
