@import "@/styles/base/base";

.footer {
    padding-top: 380px;
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 60px 50px 150px 50px;
}

.footer__leftside {
    font-size: 18px;
    color: $gray;
}

.footer__leftsideText {
    max-width: 588px;
    line-height: 22px;
    padding-bottom: 20px;
}

.footer__rightside {
    display: flex;
    gap: 25px;
}

.footer__rightsideLeft {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: $gray;
    text-align: right;
}

.footer__rightsideLeftText {
    padding-bottom: 20px;
}

.footer__contactBtn {
    color: $blue;
    padding: 11px 32px;
    border: 1px solid $blue;
    border-radius: 16px;
    max-width: 170px;
}

.footer__contactBtn:hover {
    color: $blue_login;
    border: 1px solid $light_blue;
    transition: background-color 0.5s;
}

@media (max-width: 760px) {
    .footer {
        padding-top: 105px;
    }

    .footer__leftsideText {
        font-size: 10px;
        line-height: 12px;
        max-width: 189px;
    }

    .footer__rightside {
        position: relative;
        flex-direction: column;
    }

    .footer__rightsideLeft {
        font-size: 10px;
    }

    .footer__contactBtn {
        position: absolute;
        padding: 5px 14px;
        width: 200px;
        font-size: 10px;
    }
}

@media (max-width: 375px) {
    .footer {
        padding-top: 20px;
    }

    .footer__wrapper {
        gap: 10px;
        padding-bottom: 0;
        padding-right: 20px;
        padding-left: 20px;
    }

    .footer__leftsideText {
        font-size: 8px;
        max-width: 150px;
        line-height: 10px;
    }

    .footer__rightside {
        max-width: 130px;
        text-align: left;
        font-size: 8px;
    }

    .footer__rightsideLeft {
        max-width: 140px;
        font-size: 8px;
        text-align: left;
    }

    .footer__rightsideLeftText {
        padding-bottom: 10px;
    }

    .footer__contactBtn {
        right: 20px;
        width: 150px;
    }
}
