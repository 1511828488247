@import "@/styles/base/base";
@import "@/styles/blocks/trial.module";
@import "@/styles/blocks/platform.module";

.how__it__works {
    padding-top: 165px;
}

.how__it__worksTitle {
    text-align: center;
    font-size: 96px;
    font-weight: 600;
}

.how__it__worksWrapper {
    display: flex;
    gap: 55px;
    padding: 67px 50px 0 50px;
}

.how__it__worksBlocks {
    display: flex;
    flex-direction: column;
    gap: 400px;
    padding-top: 150px;
}

.how__it__worksBlockTitle {
    max-width: 800px;
    font-size: 50px;
    font-weight: 600;
}

.how__it__worksBlockText {
    max-width: 854px;
    font-size: 40px;
    padding-top: 115px;
}

.how__it__worksBlockWrapper {
    display: flex;
}

.how__it__worksBlockImg {
    display: none;
}

.how__it__worksBlockText {
    transition: max-height 0.3s ease;
    overflow: hidden;
    max-height: 1000px;
}

@media (max-width: 1000px) {
    .how__it__worksBlockTitle {
        font-size: 50px;
    }

    .how__it__worksWrapper {
        padding: 0 50px 0 50px;
    }
    .how__it__worksImage {
        display: none;
    }

    .how__it__worksBlocks {
        gap: 150px;
        padding-top: 80px;
    }

    .how__it__worksBlockTitle {
        font-size: 35px;
    }

    .how__it__worksBlockText {
        font-size: 25px;
        padding-top: 70px;
    }
}

@media (max-width: 760px) {
    .how__it__works {
        padding-top: 40px;
    }
    .how__it__worksTitle {
        font-size: 24px;
    }

    .how__it__worksBlocks {
        gap: 65px;
        padding-top: 65px;
    }

    .how__it__worksBlockTitle {
        font-size: 18px;
        width: 100%;
    }

    .how__it__worksBlockText {
        font-size: 14px;
        padding-top: 30px;
    }

    .how__it__worksBlockImg {
        display: block;
        padding-left: 10px;
    }

    .how__it__worksBlockText.collapsed {
        display: none;
    }

    .collapsed {
        display: block;
    }
}

@media (max-width: 375px) {
    .how__it__works {
        padding-top: 35px;
    }
    .how__it__worksWrapper {
        display: block;
        gap: 0;
        padding-top: 0;
        padding-left: 40px;
        padding-right: 20px;
    }

    .how__it__worksTitle {
        font-size: 18px;
    }

    .how__it__worksImage {
        display: none;
    }

    .how__it__worksBlocks {
        gap: 40px;
        padding-top: 60px;
    }

    .how__it__worksBlockTitle {
        font-size: 14px;
        line-height: 17px;
        width: 100%;
    }

    .how__it__worksBlockText {
        max-width: 100%;
        font-size: 12px;
        padding-top: 10px;
    }
}
