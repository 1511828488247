@import "../base/vars";

.platform {
    padding: 100px 28px 0 28px;
}

.platform__wrapper {
    display: flex;
    justify-content: space-between;
}

.platform__title {
    font-size: 100px;
    font-weight: 700;
}

.platform__text {
    font-size: 54px;
    padding-top: 10px;
}
.platform__images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
}

.platform__imagesLaptop {
    grid-column: 1 / span 9;
    grid-row: 1;
    padding-top: 10%;
    z-index: 1;
}

.platform__imagesGreen {
    grid-column: 3 / -1;
    grid-row: 1;
}

.platform__dotLine {
    text-align: center;
    padding-top: 110px;
    width: 100%;
}

.green {
    color: $green;
}

@media (max-width: 1000px) {
    .platform {
        padding-top: 50px;
    }
    .platform__wrapper {
        display: flex;
        justify-content: center;
    }

    .platform__images {
        display: none;
    }
}

@media (max-width: 760px) {
    .platform {
        padding-top: 40px;
    }
    .platform__title {
        font-size: 40px;
        text-align: center;
    }

    .platform__text {
        font-size: 16px;
        text-align: center;
    }

    .platform__wrapper {
        flex-direction: column;
    }

    .platform__dotLine {
        padding-top: 160px;
    }
}

@media (max-width: 375px) {
    .platform {
        padding: 0;
    }

    .platform__wrapper {
        flex-direction: column;
        padding: 35px 55px 50px 55px;
    }

    .platform__texts {
        text-align: center;
    }

    .platform__title {
        font-size: 30px;
    }

    .platform__text {
        font-size: 14px;
        padding-top: 0px;
    }

    .platform__dotLine {
        background-image: url("/assets/img/dot-line375.svg");
        padding-top: 9px;
        margin-top: 120px;
    }

    .platform__imagesLine {
        display: none;
    }
}
